<template>
  <div class="hero-body">
    <div class="container has-text-centered">
        <section id="keypoints" class="section is-medium has-text-black">
            <div class="columns">
                <div class="column is-full">
                    <div class="content">
                        <span class="icon-text">
                        <span class="icon">
                            <i class="fas fa-euro-sign"></i>
                        </span>
                        <span>Prix: 1000€ HT / 1200€ TTC</span>
                        </span>
                    </div>

                    <div class="content">
                        <span class="icon-text">
                        <span class="icon">
                            <i class="fas fa-clock"></i>
                        </span>
                        <span>Durée: 9 jours / 63 heures</span>
                        </span>
                    </div>

                    <div class="content">
                        <span class="icon-text">
                        <span class="icon">
                            <i class="fas fa-user"></i>
                        </span>
                        <span>Public: Tout public</span>
                        </span>
                    </div>

                    <div class="content">
                        <span class="icon-text">
                        <span class="icon">
                            <i class="fas fa-list-check"></i>
                        </span>
                        <span>Délai d'accès: De 2 mois à 14 jours avant le début de la formation</span>
                        </span>
                    </div>
                </div>
            </div>
        </section>

        <section id="needed" class="section is-medium has-text-black">
            <div class="content"><p class="title has-text-black">Pré-requis</p></div>
            <div class="columns">
                <div class="column is-one-fifth">
                    <div class="content">
                        <p>
                            <span class="icon is-large">
                                <i class="fas fa-2x fa-scale-balanced"></i>
                            </span>
                        </p>
                        <p>Casier judiciaire vierge</p>
                    </div>
                </div>

                <div class="column is-one-fifth">
                    <div class="content">
                        <p>
                            <span class="icon is-large">
                                <i class="fas fa-2x fa-language"></i>
                            </span>
                        </p>
                        <p>Parler, lire & écrire français</p>
                    </div>
                </div>

                <div class="column is-one-fifth">
                    <div class="content">
                        <p>
                            <span class="icon is-large">
                                <i class="fas fa-2x fa-user"></i>
                            </span>
                        </p>
                        <p>Avoir 18 ans minimum</p>
                    </div>
                </div>

                <div class="column is-one-fifth">
                    <div class="content">
                        <p>
                            <span class="icon is-large">
                                <i class="fas fa-2x fa-id-card"></i>
                            </span>
                        </p>
                        <p>Permis B</p>
                    </div>
                </div>

                <div class="column is-one-fifth">
                    <div class="content">
                        <p>
                            <span class="icon is-large">
                                <i class="fas fa-2x fa-heart"></i>
                            </span>
                        </p>
                        <p>Avoir une bonne condition physique</p>
                    </div>
                </div>
            </div>
        </section>

        <section id="goals" class="section is-medium has-text-black">
            <div class="content">
                <h1 class="has-text-black">Objectif de la formation</h1>
                <h2 class="has-text-black">L'agent de piste bagagiste est responsable de la gestion efficace et sécurisée des bagages des passagers dans un aéroport.</h2>
                <p>Appliquer les procédures de chargement et de déchargement des avions</p>
                <p>Respecter les gestes et postures adéquates</p>
                <p>Être en mesure de se déplacer sur un aéroport</p>
                <p>Conduire des engins spécifiques</p>
                <p>Identifier les bagages non-autorisés au chargement</p>
                <p>Lire et compléter un plan de chargement</p>
                <p>Identifier les erreurs de chargement des bagages</p>
                <p>Utiliser les EPI correspondant à chaque activité</p>
                <p>Assurer les opérations de manutention (dans les galeries de bagages et sur la piste)</p>
                <p>Communiquer avec l’équipe de pilotage pour positionner l’avion (balisage)</p>
            </div>
        </section>

        <section id="content" class="section is-medium has-text-black">
            <div class="content">
                <h1 class="has-text-black">Programme détaillé</h1>
                <h2 class="has-text-black">Module 1: Environnement aéroportuaire</h2>
                <p>Description Métier</p>
                <p>Réglementation du transport aérien</p>
                <p>Différentes zones aéroportuaires et intervenants</p>
                <p>Types d'avions</p>
                <h2 class="has-text-black">Module 2: Procédures techniques</h2>
                <p>Chargement et déchargement bagage et fret</p>
                <p>Les soutes et engins</p>
                <p>Les types de marchandises</p>
                <p>Le plan de chargement et les étiquettes bagages</p>
                <p>Guidage avion et gestes conventionnels</p>
                <h2 class="has-text-black">Module 3: Sûreté et sécurité</h2>
                <p>Sûreté 11.2.3.8</p>
                <p>Sûreté 11.2.3.9</p>
                <p>Sûreté 11.2.6.2</p>
                <p>Sécurité piste piéton</p>
                <p>Marchandises dangereuses H.7.4R</p>
                <p>Coactivité</p>
                <p>Facteur humain SGS</p>
                <p>Gestes et postures</p>
            </div>
        </section>

        <section id="methodology" class="section is-medium has-text-black">
            <div class="content">
                <h1 class="has-text-black">Modalités pédagogiques</h1>
                <p>Exercices d’applications</p>
                <p>Mises en situation et jeux de rôles</p>
                <p>Vidéos</p>
                <p>Évaluations formatives tout le long de la formation (test oral / écrit)</p>
                <p>Évaluations sûreté et DGR</p>
                <p>Évaluation sommative (fin de formation)</p>
            </div>
        </section>
    </div>
  </div>
</template>

<script setup>
</script>

<style scoped>
#keypoints {
  background-color: #ffb703;
}
#needed {
  background-color: #219ebc;
}
#goals {
  background-color: #fb8500;
}
#content {
  background-color: #8ecae6;
}
#methodology {
  background-color: #ffb703;
}
</style>

<template>
  <section class="section is-medium">
    <p class="title">
      P&J Recrutement & Conseil
    </p>
    <p class="subtitle">
      Votre réussite nous importe
    </p>
  </section>

  <!-- <section class="section is-large video">
    <video 
      poster="//static.wixstatic.com/media/b274f8_07330d0a2b1d47b2bfe60944c3a3578ef000.jpg/v1/fill/w_1905,h_553,al_c,q_85,usm_0.33_1.00_0.00,enc_auto/b274f8_07330d0a2b1d47b2bfe60944c3a3578ef000.jpg" 
      src="//video.wixstatic.com/video/b274f8_07330d0a2b1d47b2bfe60944c3a3578e/1080p/mp4/file.mp4" 
      crossorigin="anonymous" 
      playsinline="" 
      preload="auto" 
      muted="" 
      loop="" 
      tabindex="-1" 
      autoplay="" 
      />
  </section> -->
</template>

<script setup>
</script>

<style scoped>
section {
  background-color: #ffb703;
}
</style>

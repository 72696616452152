<template>
  <div class="hero-body">
    <div class="container has-text-centered">
      <section class="section is-medium has-text-black">
        <div class="content">
          <h1 class="has-text-black">Mentions légales</h1>
          <p>Identité du propriétaire du site : PJ Recrutement et Conseil, 28, Avenue des Pépinières, 94260 Fresnes, 07 82 49 72 17, contact@pj-recrutement-conseil.com.</p>
          <p>Directeur de la publication : Gace Derby Jessy</p>
          <p>Hébergement du site : CLOUDFLARE FRANCE SAS</p>
          <p>Siège social : 6 place de la Madeleine 75008 Paris, France</p>
          <p>Tél. +33 (0)8 97 12 90 54</p>
          <p>https://www.cloudflare.com/fr-fr/</p>
          <p>Conditions d'utilisation : L'utilisation de ce site est soumise aux conditions générales d'utilisation qui sont indiquées sur le site et qui peuvent être modifiées à tout moment sans préavis. En utilisant ce site, vous acceptez les conditions générales d'utilisation en vigueur.</p>
          <p>Protection des données personnelles : Conformément à la réglementation en vigueur sur la protection des données personnelles, PJ Recrutement et Conseil s'engage à protéger la confidentialité des informations fournies par les utilisateurs du site. Les données collectées sont utilisées uniquement dans le cadre de la prestation de services proposée par PJ Recrutement et Conseil.</p>
          <p>Propriété intellectuelle : Le contenu de ce site, y compris les textes, images, sons et tout autre élément, est protégé par la législation sur la propriété intellectuelle. Toute reproduction, diffusion ou exploitation non autorisée de ce contenu est strictement interdite.</p>
          <p>Limitation de responsabilité : PJ Recrutement et Conseil ne pourra être tenu responsable en cas d'impossibilité d'accès à ce site ou de tout dommage résultant de l'utilisation du site, y compris les dommages indirects ou incidents.</p>
          <p>Liens vers d'autres sites : Ce site peut contenir des liens vers d'autres sites qui ne sont pas gérés par PJ Recrutement et Conseil. PJ Recrutement et Conseil ne peut être tenu responsable du contenu de ces sites ni de l'utilisation qui pourra en être faite.</p>
          <p>Loi applicable : Les présentes mentions légales sont soumises au droit français. Tout litige sera soumis aux tribunaux compétents de Bobigny.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup>
</script>

<style scoped>
section {
  background-color: #fb8500;
}
</style>

<template>
    <section class="section is-medium">
        <div class="columns is-centered">
            <div class="column is-3">
                <div class="box">
                    <article class="message">
                    <div class="message-header">
                        <p>
                            <router-link to="/formation/piste-bagagiste">Agent de piste aéroportuaire</router-link>
                        </p>
                    </div>
                    <div class="message-body">
                        <p>Réaliser des opérations de chargement et déchargement de bagages, marchandises et fret dans les soutes avion, tout en respectant les procédures compagnie et en appliquant les règles de sûreté et sécurité liés à l'exercice de vos fonctions.</p>
                        <p></p>
                    </div>
                    </article>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
</script>

<style scoped>
section {
  background-color: #fb8500;
}
</style>

<template>
  <div class="hero-body">
    <div class="container has-text-centered">
      <section class="section is-medium has-text-black">
        <div class="content">
          <h1 class="has-text-black">Termes et conditions</h1>
          <p>Objet : Les présentes conditions générales de vente régissent les relations entre PJ Recrutement et Conseil et toute personne souhaitant acquérir des prestations de formation (ci-après dénommée « Stagiaire »).</p>
          <p>Formation proposées : PJ Recrutement et Conseil propose des prestations de formation dans les métier aéroportuaires et de sécurité. Les prestations sont décrites sur le site web pj-recrutement-conseil.com.</p>
          <p>Inscription : L’inscription se fait uniquement sur rendez-vous dans nos locaux au 28, Avenue des Pépinières, 94260 Fresnes. Le paiement de la formation doit être effectué avant le début de celle-ci.</p>
          <p>Prix : Les prix des prestations de formation sont ceux en vigueur au moment de l’inscription. Ils sont indiqués sur le site web de PJ Recrutement et Conseil et peuvent être soumis à modification à tout moment.</p>
          <p>Annulation et remboursement : En cas d’annulation de la part du Stagiaire, PJ Recrutement et Conseil se réserve le droit de facturer des frais d’annulation selon les modalités suivantes :</p>
          <p>Politique de remboursement et d'annulation à 48 heures avant le début de la formation :</p>
          <p>- Les demandes de remboursement doivent être soumises par écrit (par courrier électronique ou postal) 48 heures avant le début de la formation.</p>
          <p>- Les remboursements seront effectués intégralement, à l'exception des frais administratifs de 10% du coût total de la formation.</p>
          <p>- En cas d'annulation par le participant, aucun remboursement ne sera effectué si la demande est reçue moins de 48 heures avant le début de la formation.</p>
          <p>- En cas d'annulation de la formation par l'organisateur, un remboursement intégral sera effectué aux participants.</p>
          <p>- Les participants peuvent choisir de transférer leur inscription à une prochaine session de formation sans frais supplémentaires.</p>
          <p>- Si un participant ne peut pas assister à la formation pour une raison indépendante de sa volonté (par exemple, maladie ou décès), il peut demander un remboursement ou un transfert d'inscription. Les décisions seront prises au cas par cas.</p>
          <p>- Cette politique s'applique à toutes les formations organisées par notre entreprise. Les participants sont encouragés à lire attentivement cette politique avant de s'inscrire à une formation.​</p>
          <p>Modification de la formation : PJ Recrutement et Conseil se réserve le droit de modifier ou annuler une formation en cas de force majeure ou de tout autre événement imprévu. Dans ce cas, PJ Recrutement et Conseil remboursera le Stagiaire ou lui proposera une solution alternative.</p>
          <p>Propriété intellectuelle : Les supports de formation, les documents et les informations fournis par PJ Recrutement et Conseil restent sa propriété exclusive. Le Stagiaire s’engage à ne pas les diffuser, les reproduire ou les utiliser à d’autres fins que celles de sa formation.</p>
          <p>Responsabilité : PJ Recrutement et Conseil décline toute responsabilité en cas de perte ou de dommage subis par le Stagiaire pendant la formation. Le Stagiaire s’engage à indemniser PJ Recrutement et Conseil en cas de dommage causé à autrui pendant la formation.</p>
          <p>Tout litige sera de la compétence exclusive des tribunaux de Bobigny</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup>
</script>

<style scoped>
section {
  background-color: #fb8500;
}
</style>
